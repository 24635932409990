import { Injectable } from '@angular/core';
import { BlocksIcon, BuildingIcon, CableIcon, ImportIcon, UserIcon, UsersIcon } from 'lucide-angular';
import { NavRoute } from '../interfaces/nav-route';
import { environment } from '../../environments/environment';
import posthog from 'posthog-js';
import { settingsRoutes } from '../pages/settings/common/settings.constants';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	constructor() {}

	public integrations = (): boolean  => {
		if (environment.posthog) {
			return posthog.isFeatureEnabled('xero');
		}
	}

	getRoutes(): NavRoute[] {
		return [
			{ name: 'Account', route: `/admin/${settingsRoutes.accountDetails}`, icon: UserIcon},
			{ name: 'Companies', route: `/admin/${settingsRoutes.companies}`, icon: BuildingIcon},
			{ name: 'Divisions', route: `/admin/${settingsRoutes.divisions}`, icon: BlocksIcon },
			{ name: 'Users', route: `/admin/${settingsRoutes.users}`, icon: UsersIcon },
			{ name: 'Import Data', route: `/admin/${settingsRoutes.importData}`, icon: ImportIcon },
			{ name: 'Integrations', route: `/admin/${settingsRoutes.integrations}`, icon: CableIcon, disabled: this.integrations()}
		]
	}
}

import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { NavRoute } from '../../../../interfaces/nav-route';
import { libraryRoutes } from './libraries.constants';
import {
	Building2,
	DollarSign,
	Folder,
	ListTodo,
	Megaphone,
	Paintbrush,
	ScanBarcode,
	SlidersHorizontal,
	SwatchBook,
	Target,
	Users,
} from 'lucide-angular';

@Injectable({
	providedIn: 'root',
})
export class LibrariesService {
	constructor(private localStorageService: LocalStorageService) {}

	private canUploadDocuments(): boolean {
		const currentUser = this.localStorageService.getUserDetails();
		return currentUser.entity?.canUploadCustomDocuments;
	}

	getRoutes(): NavRoute[] {
		return [
			{ name: 'Brands', route: `/libraries/${libraryRoutes.brands}`, icon: Building2},
			{ name: 'Coating Systems', route: `/libraries/${libraryRoutes.coatingSystems}`, icon: SlidersHorizontal },
			{ name: 'Colours', route: `/libraries/${libraryRoutes.colours}`, icon: SwatchBook },
			{ name: 'Measure Tools', route: `/libraries/${libraryRoutes.measureTools}`, icon: Paintbrush },
			{ name: 'Competitors', route: `/libraries/${libraryRoutes.competitors}`, icon: Target },
			{
				name: 'Files/Folders',
				route: `/libraries/${libraryRoutes.fileFolders}`,
				icon: Folder,
				disabled: !this.canUploadDocuments(),
				subRoutes: [
					{ name: 'Tender Document', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.tenderDocument}`},
					{ name: 'Quote Proposal', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.quoteProposal}`},
					{ name: 'Office Folder', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.officeFolder}`},
					{ name: 'Site Folder', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.siteFolder}`},
					{ name: 'Safety Folder', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.safetyFolder}`},
					{ name: 'QA Folder', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.qaFolder}`},
					{ name: 'Variation Quote', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.variationQuote}`},
					{ name: 'Quote', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.quote}`},
					{ name: 'Bill of Quantity', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.boq}`},
					{ name: 'Product Purchasing', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.productPurchasing}`},
					{ name: 'Invoice', route: `/libraries/${libraryRoutes.fileFolders}/${libraryRoutes.invoice}`},
				]
			},
			{ name: 'Inclusions/Exclusions', route: `/libraries/${libraryRoutes.inclusionsExclusions}`, icon: ListTodo},
			{ name: 'Labour Rates', route: `/libraries/${libraryRoutes.labourRates}`, icon: DollarSign},
			{ name: 'Products', route: `/libraries/${libraryRoutes.products}`, icon: ScanBarcode},
			{ name: 'Suppliers', route: `/libraries/${libraryRoutes.suppliers}`, icon: Users},
			{ name: 'Marketing Items', route: `/libraries/${libraryRoutes.marketingItems}`, icon: Megaphone},
		]
	}
}

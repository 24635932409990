import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { Constants, FileMimeTypeEnum, MAX_FILE_SIZE, SnackBarHelperComponent, TEXT } from '../../../helpers';
import { FileUploadService } from '../../../services/file-uploader.service';
import { FileUploaderConst } from './common/file-uploader-constants';
import { FileMimeTypesInterface } from './common/file-uploader.interfaces';

@Component({
	selector: 'app-file-uploader',
	templateUrl: 'file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnDestroy {
	@ViewChild('fileUpload', { static: true })
	private fileUpload: ElementRef;

	@Input()
	public set files(values: File[]) {
		this._files = values;
		this.isFileLarge = values[0]?.size / 1024 / 1024 > 25;
		if ((!values || !values.length) && this.fileUpload) {
			// Manually clear fileUpload value to allow upload of the same file
			this.fileUpload.nativeElement.value = '';
		}
	}

	public get files(): File[] {
		return this._files;
	}

	@Input()
	public set allowedFileMimeTypes(types: FileMimeTypesInterface) {
		this._allowedFileMimeTypes = types;
		// Set allowed file types for file picker
		this.allowedFileMimeTypesString = this._allowedFileMimeTypes ? this._allowedFileMimeTypes.array?.join(',') : undefined;
		this.fileTypeName = this._allowedFileMimeTypes.type;
		this.isFileTypeImage = this.fileTypeName === 'image';
	}
	public get allowedFileMimeTypes(): FileMimeTypesInterface {
		return this._allowedFileMimeTypes;
	}
	@Input()
	public disabled: boolean = false;
	@Input()
	public hidePreview: boolean = false;
	@Input()
	public multi: boolean = false;
	@Input()
	public uploadMessage: string;

	@Output()
	public filesChange = new EventEmitter<File[]>(true);
	@Output()
	public s3KeyChange = new EventEmitter<string>();

	public allowedFileMimeTypesString: string;
	public fileTypeName: string;
	public fileUploaderConst: typeof FileUploaderConst = FileUploaderConst;
	public isFileLarge: boolean = false;
	public isFileTypeImage: boolean = false;
	public SVG_ICON_PATHS = Constants.SVG_ICON_PATHS;
	public textConstants: typeof TEXT = TEXT;

	private _allowedFileMimeTypes: FileMimeTypesInterface;
	private cancelSub: Subscription;
	private _files: File[] = [];

	constructor(
		private fileUploadService: FileUploadService,
		private snack: SnackBarHelperComponent
	) {}

	public ngOnInit(): void {
		// Subscriptions
		this.cancelSub = this.fileUploadService.cancelObs.subscribe(() => this.cancelUpload());
	}

	public ngOnDestroy(): void {
		if (this.cancelSub) {
			this.cancelSub.unsubscribe();
		}
	}

	/**
	 * Remove all file(s), emit cancel upload event and reset upload progress
	 */
	public cancelUpload(): void {
		this.setSelectedFiles([]);
	}

	/**
	 * Add dragged files to list
	 * If multi is true append to existing file list
	 */
	public dragSelectedFiles(fileList?: File[]): void {
		const fileArray = this.multi ? [...this.files, ...fileList] : fileList;
		this.setSelectedFiles(fileArray);
	}

	/**
	 * Removes one specific file
	 * @param {File} file
	 */
	public removeFile(file: File) {
		const index = this.files.indexOf(file);
		this.files.splice(index, 1);

		this.setSelectedFiles(this.files);
	}

	/**l
	 * File selected in file selector
	 * @param fileList
	 */
	public onFileSelect(fileList: File[]): void {
		const selectedFiles = [];
		if (fileList && fileList.length > 0) {
			for (const file of fileList) {
				selectedFiles.push(file);
			}

			this.setSelectedFiles(selectedFiles);
		}
	}

	public setSelectedFiles(fileList?: File[]): void {
		const fileArray = [];

		// Check validity of files
		if (fileList && fileList.length) {
			for (const file of fileList) {
				// File size limit is 1GB
				if (file.size > MAX_FILE_SIZE) {
					this.snack.snackError(Constants.FILE_TOO_BIG_MESSAGE);
					return;
				}
				if (this.allowedFileMimeTypesString === FileMimeTypeEnum.pdf && file.type !== FileMimeTypeEnum.pdf) {
					this.snack.snackError(Constants.PDF_ONLY_MESSAGE);
					this.filesChange.emit([]);
					return;
				}
				fileArray.push(file);
			}
		}
		this.files = fileArray;

		this.filesChange.emit(fileList);
	}

	/**
	 * Opens the file in a new tab.
	 * @param file
	 */
	public openFile(file: File) {
		const url = window.URL.createObjectURL(file);
		window.open(url, '_blank');
	}

	protected readonly TEXT = TEXT;
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Constants, ERROR_MESSAGES, LOGO_UPLOADER_TITLES, NgSelectCustomFunctionHelper, SnackBarHelperComponent, ValidatorHelper } from '../../../../../helpers';
import { Contact, GetAddContactValidation, State } from '../../../../../interfaces';
import { UserModel } from '../../../../../models';
import { AuthService } from '../../../../../services/auth.service';
import { S3Service } from '../../../../../services/s3.service';
import { StateService } from '../../../../../services/state.service';
import { UserService } from '../../../../../services/user.service';
import { AddContactTextConst } from '../add-contact-person/common/add-contact-person.constants';
import { ProjectRequestService } from '../../../../../services/project-request.service';
import { RolePermissionsService } from '../../../../../services/role-permissions.service';
import { ProjectRequest } from '../../../../../pages/submit-project/common/submit-project.model';

@Component({
	selector: 'app-add-edit-contact-dialog',
	// this template is used in two components
	templateUrl: '../../add-contact-person-dialog.component.html',
	styleUrls: ['../../add-contact-person-dialog.component.css'],
})
export class AddEditContactDialogComponent implements OnInit {
	@Input() public client: FormGroup;

	@Input()
	public set selectedContact(contact: Contact) {
		this.resetFields();
		if (contact) {
			this.newContact = Object.assign({}, contact);
		}
	}
	public get selectedContact(): Contact {
		return this._selectedContact;
	}

	@ViewChild('iframeId', { static: false }) public iframeId: ElementRef;

	public newContact: Contact;
	public readonly pictureUploadTitle = LOGO_UPLOADER_TITLES.picture;
	public users: UserModel[];
	public sameAsClient: boolean = false;
	public states: Observable<State[]>;
	public getDataPromise: Promise<void>;
	public estimators: Observable<UserModel[]>;
	public newEstimator: UserModel;
	public isAdmin: boolean;
	public contactValidators;
	public isAvatarImage: boolean = false;
	public baseAvatarUrl: SafeResourceUrl;
	public text: typeof AddContactTextConst = AddContactTextConst;
	public projectRequestData: ProjectRequest;

	private _selectedContact: Contact;

	constructor(
		private userService: UserService,
		private rolePermissionsService: RolePermissionsService,
		private stateService: StateService,
		private snack: SnackBarHelperComponent,
		private sanitizer: DomSanitizer,
		private s3Service: S3Service,
		private projectRequestService: ProjectRequestService
	) {}

	public ngOnInit() {
		this.isAdmin = this.rolePermissionsService.isAdmin();
		this.contactValidators = GetAddContactValidation();
		this.baseAvatarUrl = this.sanitizer.bypassSecurityTrustResourceUrl(Constants.BASE_AVATAR_URL);
		this.estimators = this.userService.getUsers({}).pipe(
			tap((users: UserModel[]) => {
				this.users = users;
			})
		);
		this.states = this.stateService.getAllStates({ isActive: true });
	}

	public resetFields(): void {
		this.sameAsClient = false;
		this.newContact = { name: '', estimators: [] };
		this.contactValidators = GetAddContactValidation();
	}

	public addEstimatorToNewContact(estimator: UserModel) {
		// guard
		if (!estimator) {
			return;
		}

		if (!this.newContact.estimators) {
			this.newContact.estimators = [];
		}

		if (this.newContact.estimators.indexOf(estimator) < 0) {
			this.newContact.estimators.push(estimator);
			this.newEstimator = undefined;
		} else {
			this.snack.snackError(ERROR_MESSAGES.duplicateEstimator);
		}
	}

	/**
	 * Add a new contact to the list of contacts
	 */
	public async saveContact() {
		const error = ValidatorHelper.checkErrors(this.contactValidators);
		if (error) {
			this.snack.snackError(error);
			return;
		}

		if (this.isAvatarImage) {
			this.newContact.imageFile = await this.saveAvatarSvg();
		}

		return this.newContact;
	}

	public saveAvatarSvg(): Promise<File | Blob> {
		this.iframeId.nativeElement.contentWindow.postMessage('svg', '*');
		return new Promise(resolve => {
			window.addEventListener('message', function (event) {
				if (event.data.name === 'svg' && event.data.svg) {
					const svgBlob: Blob = new Blob([event.data.svg], {
						type: 'image/svg+xml;charset=utf-8',
					});
					resolve(svgBlob);
				}
			});
		});
	}

	public removeEstimator(user: UserModel) {
		const index: number = this.newContact.estimators.findIndex(estimator => estimator.id === user.id);
		this.newContact.estimators.splice(index, 1);
	}

	public customSearch(term: string, item: UserModel) {
		return NgSelectCustomFunctionHelper.userNameSearch(term, item);
	}

	public setFile(file: File): void {
		if (file) {
			this.newContact.imageFile = file;
		}
	}


	public onSameAsClient() {
		this.projectRequestData = this.projectRequestService.getSelectedProjectRequest();
		if (this.sameAsClient) {
			this.newContact = {
				name: this.projectRequestData ? this.projectRequestData.contactName : this.client.value.name,
				email: this.client.value.email,
				phone: this.client.value.phone,
				addressLine1: this.client.value.addressLine1,
				addressLine2: this.client.value.addressLine2,
				addressLine3: this.client.value.addressLine3,
				city: this.client.value.city,
				state: this.client.value.state,
				postalCode: this.client.value.postalCode,
				estimators: this.newContact.estimators,
			}
		} else {
			this.newContact = {
				name: '',
				email: '',
				phone: '',
				addressLine1: '',
				addressLine2: '',
				addressLine3: '',
				city: '',
				state: undefined,
				postalCode: '',
				estimators: this.newContact.estimators,
			}
		}
	}
}

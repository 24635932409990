export enum settingsRoutes {
	company = 'companies/new',
	companyId = 'company/:id',
	companies = 'companies',
	createDivisions = 'divisions/new',
	divisions = 'divisions',
	divisionId = 'division/:id',
	accountDetails = 'account-details',
	importData = 'import-data',
	users = 'users',
	userGroups = 'userGroups',
	integrations = 'external-accounting',
}

export const SettingsPageText = {
	settings: 'settings',
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { Attachment, AttachmentResponse, ItemsResponse } from '../interfaces';

@Injectable()
export class AttachmentService {
	constructor(private http: HttpClient) {}

	/**
	 * Gets an attachment
	 * @param projectId
	 * @param attachmentName
	 * @param retryQuery
	 */
	public postDetails(projectId, attachmentName, retryQuery = false) {
		return this.http
			.post<AttachmentResponse>(`${Constants.BASE_API_URL}/project/attachment/details`, { projectId, attachmentName, retryQuery })
			.pipe(map(res => res.attachment));
	}

	/**
	 * Adds a new attachment to a project
	 * @param {Attachment} attachment
	 * @param {string} projectId
	 * @returns {Observable<Attachment>}
	 */
	public addAttachment(attachment, projectId?) {
		return this.http.post<AttachmentResponse>(`${Constants.BASE_API_URL}/project/attachment`, { attachment, projectId }).pipe(map(res => res.attachment));
	}

	/**
	 * Adds a new attachment to a project. From Export tab
	 * @param {Attachment} attachment
	 * @param {string} projectId
	 * @param {string} folderName
	 * @returns {Observable<AttachmentResponse>}
	 */
	public addAttachmentFromFolder(attachment: Attachment, projectId: string, folderName: string) {
		return this.http
			.post<AttachmentResponse>(`${Constants.BASE_API_URL}/project/attachment/create-from-folder`, {
				projectId,
				attachment,
				folderName,
			})
			.pipe(map(res => res.attachment));
	}

	/**
	 * Clone an existing attachment into the "Tender Documents" folder
	 * 
	 * @param {string} attachmentId
	 * @returns {Observable<AttachmentResponse>}
	 */
	public cloneToTenderFolder(attachmentId: string) {
		return this.http
			.post<AttachmentResponse>(`${Constants.BASE_API_URL}/project/attachment/clone-to-tender-folder`, { attachmentId })
			.pipe(map(res => res.status));
	}

	/**
	 * Gets a list of attachments for the provided project
	 * @param projectId
	 * @param sortBy
	 * @param attachmentTypesToExclude
	 */
	public postList(projectId, sortBy?, attachmentTypesToExclude = []) {
		return this.http.post<ItemsResponse>(`${Constants.BASE_API_URL}/project/attachment/list`, { projectId, sortBy, attachmentTypesToExclude }).pipe(map(res => res.items));
	}

	/**
	 * Gets a list of attachments that contain images to be used in the estimator tool for the provided project
	 * @param projectId
	 */
	public postEstimatorToolDrawingList(projectId) {
		return this.http.post<ItemsResponse>(`${Constants.BASE_API_URL}/project/attachment/drawing-list`, { projectId }).pipe(map(res => res.items));
	}

	/**
	 * Edits the active state of an attachment given its id and desired state. Returns updated attachment
	 * @param {string} id
	 * @param {boolean} isActive
	 * @param {string} projectId
	 * @returns {Observable<Attachment>}
	 */
	public editActive(id: string, isActive: boolean, projectId: string): Observable<Attachment> {
		return this.http.post<AttachmentResponse>(`${Constants.BASE_API_URL}/project/attachment/edit-active`, { id, isActive, projectId }).pipe(map(res => res.attachment));
	}

	/**
	 * Upload-file and return s3 key
	 * @param formData FormDat
	 * @param id string
	 */
	public postUploadFileAndGetAttachment(formData: FormData): Observable<Attachment> {
		return this.http.post<AttachmentResponse>(`${Constants.BASE_API_URL}/project/attachment/upload-file`, formData).pipe(map(res => res.attachment));
	}
}

import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Constants, SnackBarHelperComponent, TEXT, ValidatorHelper } from '../../../helpers';
import { Competitor, GetAddressValidation, GetProjectStatusValidation, Project, ProjectSaveObj } from '../../../interfaces';
import { ProjectRouteService } from '../../../pages/project/common/project.service';
import { InvoicesData } from '../../../pages/project/invoice/common/invoice.interfaces';
import { CompetitorService } from '../../../services/competitor.service';
import { ProjectService } from '../../../services/project.service';
import { RolePermissionsService } from '../../../services/role-permissions.service';
import { UserModel } from '../../../models';
import { UserService } from '../../../services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-edit-project-status-dialog',
	templateUrl: './edit-project-status-dialog.component.html',
	styleUrls: ['./edit-project-status-dialog.component.scss'],
})
export class EditProjectStatusDialogComponent implements OnInit {

	@Input()
	public set project(project: Project) {
		this._project = project;
		this.projectStatus = project.status;
		this.quoteDueDate = project.quoteDueDate;
		this.statusReason = project.statusReason;
		this.statusOtherReason = project.statusOtherReason;
		this.submittedDate = project.submittedDate;
		this.statusDate = project.statusDate ? project.statusDate : this.currentDate;
		this.startDate = project.projectStartDate;
		this.finishDate = project.projectFinishDate
		this.lostCompetitor = project.lostCompetitor;
		this.selectedClient = project.clientWonLost ? project.clientWonLost : project.clients.length === 1 ? project.clients[0]?.company.id : null;
		this.siteAddressValidators = GetAddressValidation(this._project.addressDetail, this._project.status, true);
		this.selectedContractAdministrator = project.contractAdministrator?.id;
		this.selectedProjectManager = project.projectManager?.id;
	}

	public get project(): Project {
		return this._project;
	}

	@Output()
	public onProject: EventEmitter<Project> = new EventEmitter<Project>();

	public competitors: Competitor[] = [];
	public currentDate: Date = new Date();
	public LABELS = Constants.EDIT_PROJECT_STATUS_LABELS;
	public lostCompetitor: Competitor;
	public PROJECT_STATUS = Constants.PROJECT_STATUS;
	public PROJECT_STATUS_ARRAY = Constants.PROJECT_STATUS_ARRAY;
	public PROJECT_STATUS_REASONS = Constants.PROJECT_STATUS_REASONS;
	public projectStatus: string;
	public quoteDueDate: Date;
	public savingStatusPromise: Promise<Project>;
	public selectedClient: string;
	public siteAddressValidators: { [key: string]: UntypedFormControl };
	public startDate: Date;
	public finishDate: Date;
	public statusDate: Date;
	public statusOtherReason: string;
	public statusReason: string;
	public submittedDate: Date;
	public statusValidators;
	public textConstants: typeof TEXT = TEXT;
	public invoicesLoader: Observable<InvoicesData>;
	public claimedToDate: number = 0;
	// Along with won reason, contractAdministrator and projectManager must exist if it's not already set
	public selectedContractAdministrator: string;
	public selectedProjectManager: string;
	public users: UserModel[] = [];
	private destroyRef: DestroyRef = inject(DestroyRef);

	private _project: Project;

	constructor(
		private projectRouteService: ProjectRouteService,
		private projectService: ProjectService,
		private competitorService: CompetitorService,
		private rolePermissionsService: RolePermissionsService,
		private userService: UserService,
		private snack: SnackBarHelperComponent
	) {}

	public ngOnInit(): void {
		// Init project status field's validators
		this.statusValidators = GetProjectStatusValidation();
		if (!this.hasStatusReason()) {
			this.statusValidators.StatusReason.errors = null;
		}
		if (!this.hasOtherStatusReason()) {
			this.statusValidators.StatusOtherReason.errors = null;
		}
		if (!this.hasClient()) {
			this.statusValidators.Client.errors = null;
		}
		if (this.projectStatus !== this.PROJECT_STATUS.active.value) {
			this.statusValidators.QuoteDueDate.errors = null;
		}
		if (this.projectStatus !== this.PROJECT_STATUS.won.value || this.projectStatus !== this.PROJECT_STATUS.live.value || this.projectStatus !== this.PROJECT_STATUS.completed.value) {
			this.statusValidators.StartDate.errors = null;
			this.statusValidators.FinishDate.errors = null;
			this.statusValidators.ContractAdministrator.errors = null;
			this.statusValidators.ProjectManager.errors = null;
		}
		if (this.projectStatus !== this.PROJECT_STATUS.submitted.value) {
			this.statusValidators.SubmittedDate.errors = null;
		}

		this.competitorService.postList({ params: { isActive: true } }).subscribe(competitors => {
			return (this.competitors = competitors);
		});
		// Only team leaders and above can approve projects (won/lost/quoteApproved)
		if (!this.rolePermissionsService.isTeamLeaderAndAbove()) {
			this.PROJECT_STATUS_ARRAY = this.PROJECT_STATUS_ARRAY.filter(status => {
				return (
					status !== this.PROJECT_STATUS.won &&
					status !== this.PROJECT_STATUS.lost &&
					status !== this.PROJECT_STATUS.quoteApproved &&
					status !== this.PROJECT_STATUS.submitted
				);
			});
		}
		this.userService
			.getUsers({ isActive: true })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(users => {
				this.users = users;
			});
	}

	/**
	 * Indicate if the current project status needs a client
	 */
	public hasClient(): boolean {
		return this.projectStatus === Constants.PROJECT_STATUS.won.value || this.projectStatus === Constants.PROJECT_STATUS.lost.value;
	}

	/**
	 * Indicate if the status reason of the project is 'Other'
	 */
	public hasOtherStatusReason(): boolean {
		return this.statusReason === 'Other';
	}

	/**
	 * Tell if the current project status needs a reason
	 */
	public hasStatusReason(): boolean {
		return !!this.PROJECT_STATUS_REASONS[this.projectStatus];
	}

	/**
	 * Cleans WonLostClient inputs on Competitor changes
	 */
	public onChangeCompetitor(): void {
		this.selectedClient = null;
	}

	/**
	 * Clean status reason and other reason inputs on project status changes
	 */
	public onChangeProjectStatus(): void {
		// whenever we change the status, we want to update the statusDate to today
		// if the user doesn't save the change, it will revert to the previous date
		this.statusDate = this.currentDate;

		this.statusReason = undefined;
		this.onChangeStatusReason();
		// If not won, lost or decline status then valid the status reason validator
		if (!this.hasStatusReason()) {
			this.statusValidators.StatusReason.errors = null;
		}
		if (!this.hasClient()) {
			this.statusValidators.Client.errors = null;
			this.selectedClient = undefined
		}
		if (this.projectStatus !== Constants.PROJECT_STATUS.active.value) {
			this.statusValidators.QuoteDueDate.errors = null;
			this.quoteDueDate = this.project.quoteDueDate;
		}

		if (this.projectStatus !== this.PROJECT_STATUS.submitted.value) {
			this.statusValidators.SubmittedDate.errors = null;
		}

		if (this.projectStatus !== this.PROJECT_STATUS.won.value || this.projectStatus !== this.PROJECT_STATUS.live.value || this.projectStatus !== this.PROJECT_STATUS.completed.value) {
			this.statusValidators.StartDate.errors = null;
			this.statusValidators.FinishDate.errors = null;
		}
		if (this.projectStatus !== Constants.PROJECT_STATUS.won.value) {
			this.statusValidators.ContractAdministrator.errors = null;
			this.statusValidators.ProjectManager.errors = null;
			// Reset back to what they were if status is not set to won
			this.selectedContractAdministrator = this.project.contractAdministrator?.id;
			this.selectedProjectManager = this.project.projectManager?.id;
		}

		if (this.projectStatus === this.PROJECT_STATUS.won.value) {
			this.siteAddressValidators = GetAddressValidation(this._project.addressDetail, this.projectStatus, true);
		}

		if (this.projectStatus) {
			this.statusValidators.StatusDate.errors = null;
		}
	}

	public onChangeDate(date: Date, dateType: 'statusDate' | 'submittedDate' | 'startDate' | 'finishDate' | 'quoteDueDate'): void {
		if (date && this.hasDateChanged(this[dateType], date)) {
			const currentTime = this.currentDate;
			date.setHours(currentTime.getHours());
			date.setMinutes(currentTime.getMinutes());
			date.setSeconds(currentTime.getSeconds());

			switch (dateType) {
				case 'statusDate': {
					this.statusDate = date;
					this.statusValidators.StatusDate = date;
					break;
				}
				case 'submittedDate': {
					this.submittedDate = date;
					this.statusValidators.SubmittedDate = date;
					break;
				}
				case 'startDate': {
					this.startDate = date;
					this.statusValidators.StartDate = date;
					break;
				}
				case 'finishDate': {
					this.finishDate = date;
					this.statusValidators.FinishDate = date;
					break;
				}
				case 'quoteDueDate': {
					this.quoteDueDate = date;
					this.statusValidators.QuoteDueDate = date;
					break;
				}
			}
		}
	}

	// Helper function to check if only the date part has changed (ignores time)
	private hasDateChanged(oldDate: Date, newDate: Date): boolean {
		if (!oldDate || !newDate || !(oldDate instanceof Date) || !(newDate instanceof Date)) {
			return true;
		}

		return (
			oldDate.getFullYear() !== newDate.getFullYear() ||
			oldDate.getMonth() !== newDate.getMonth() ||
			oldDate.getDate() !== newDate.getDate()
		);
	}

	/**
	 * Clean other reason input on project status reason changes
	 */
	public onChangeStatusReason(): void {
		this.statusOtherReason = undefined;
		this.lostCompetitor = undefined;
		// If no 'Other' status reason then then valid status other reason validator
		if (!this.hasOtherStatusReason()) {
			this.statusValidators.StatusOtherReason.errors = null;
		}
	}

	/**
	 * Save project status
	 */
	public onSaveProjectStatus(): Observable<Project> {
		if (this.projectStatus && this.statusDate) {
			this.statusValidators.StatusDate.errors = null;
		}
		const statusErrors = ValidatorHelper.checkErrors(this.statusValidators);
		const addressErrors = ValidatorHelper.checkErrors(this.siteAddressValidators);

		let errors = '';
		if (statusErrors) {
			errors = statusErrors;
		}

		if (this.projectStatus === Constants.PROJECT_STATUS.won.value && addressErrors) {
			errors = errors + addressErrors;
		}

		if (errors.length) {
			this.snack.snackError(errors);
			return throwError(errors);
		}

		return this.projectService
			.editProjectStatus(
				this.project.id,
				this.projectStatus,
				this.statusDate,
				this.statusReason,
				this.statusOtherReason,
				this.lostCompetitor,
				this.selectedClient,
				this.quoteDueDate,
				this.submittedDate,
				this.startDate,
				this.finishDate,
				this.selectedContractAdministrator,
				this.selectedProjectManager,
			)
			.pipe(
				switchMap((project: Project) => {
					if (this.projectStatus === Constants.PROJECT_STATUS.won.value) {
						const editProject = new ProjectSaveObj(project);
						editProject.addressDetail = this.project.addressDetail;
						return this.projectService.editProject(editProject);
					} else {
						return of(project);
					}
				}),
				map(project => {
					this.projectRouteService.setProject(project);
					this.onProject.emit(project);
					return project;
				})
			);
	}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Constants } from '../helpers';
import { ProjectRequest } from '../pages/submit-project/common/submit-project.model';
import { EntityModel } from '../models';
import { AuthService } from './auth.service';

interface ProjectRequestResponse {
	projectRequest: ProjectRequest;
	success: boolean;
	message?: string;
}

@Injectable({
	providedIn: 'root',
})
export class ProjectRequestService {
	private totalRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	public requests: Observable<number> = this.totalRequests.asObservable();
	
	private projectRequestsSubject: BehaviorSubject<ProjectRequest[]> = new BehaviorSubject<ProjectRequest[]>([]);
	public projectRequests$ = this.projectRequestsSubject.asObservable();

	private selectedProjectRequestData: ProjectRequest | null = null;

	constructor(
		private http: HttpClient,
		private auth: AuthService
	) {
		if (this.auth.isAuthenticated()) {
			this.loadProjectRequests()
		}
	}

	public submitProjectRequest(projectRequest: ProjectRequest) {
		return this.http.post(`${Constants.BASE_API_URL}/project-request/requests`, projectRequest);
	}

	public loadProjectRequests(): void {
		this.http.get<{ projectRequests: ProjectRequest[] }>(`${Constants.BASE_API_URL}/project-request/requests`)
			.pipe(
				map(response => response.projectRequests)
			)
			.subscribe(requests => {
				this.projectRequestsSubject.next(requests);
				const pendingRequestsCount = requests.filter(req => req.status === 'pending').length;
				this.totalRequests.next(pendingRequestsCount);
			});
	}

	public getProjectRequests(): Observable<ProjectRequest[]> {
		return this.projectRequests$;
	}

	public acceptProjectRequest(projectRequest: ProjectRequest, projectId: string): Observable<ProjectRequest> {
		return this.http.put<ProjectRequestResponse>(`${Constants.BASE_API_URL}/project-request/requests/${projectRequest._id}/accept`, { projectId })
			.pipe(
				map(response => response.projectRequest),
				tap(() => this.loadProjectRequests())
			);
	}

	public declineProjectRequest(projectRequest: ProjectRequest): Observable<ProjectRequest> {
		return this.http.put<ProjectRequestResponse>(`${Constants.BASE_API_URL}/project-request/requests/${projectRequest._id}/decline`, {})
			.pipe(
				map(response => response.projectRequest),
				tap(() => this.loadProjectRequests())
			);
	}

	public getPublicEntity(id: string): Observable<EntityModel> {
		return this.http.get<any>(`${Constants.BASE_API_URL}/public-entity/${id}`).pipe(map(res => res.item))
	}

	public setSelectedProjectRequest(projectRequest: ProjectRequest): void {
		this.selectedProjectRequestData = projectRequest;
	}

	public getSelectedProjectRequest(): ProjectRequest | null {
		return this.selectedProjectRequestData;
	}

	public clearSelectedProjectRequest(): void {
		this.selectedProjectRequestData = null;
	}
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cost } from '../pages/project/actual-costs/common/costs.model';
import { Constants } from '../helpers';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ItemsResponseData } from '../interfaces';

interface AddCostRequest {
	amount: number;
	description: string;
	category: string;
	project: string;
}

interface AddCostResponse {
	message: string;
	status: boolean;
	cost: Cost;
}

interface ProjectCostsTotals {
	totalAmount: number;
	breakdown: {
		Materials: number;
		Additional: number;
		Labour: number;
	}
}

@Injectable()
export class CostService {
	private projectCosts = new BehaviorSubject<Cost[]>([])
	public projectCosts$ = this.projectCosts.asObservable();
	private totalCostsCount = new BehaviorSubject<number>(0);
	public totalCostsCount$ = this.totalCostsCount.asObservable();
	private totalProjectCosts = new BehaviorSubject<ProjectCostsTotals>({
		totalAmount: 0,
		breakdown: {
			Materials: 0,
			Additional: 0,
			Labour: 0
		}
	});
	public totalProjectCosts$ = this.totalProjectCosts.asObservable();

	constructor(private http: HttpClient) {
	}

	public addCost(formData: { amount: number; description: string; category: string }, projectId: string): Observable<Cost> {
		const request: AddCostRequest = {
			...formData,
			project: projectId
		}
		return this.http.post<AddCostResponse>(`${Constants.BASE_API_URL}/project/costs`, request)
			.pipe(
				map(response => response.cost),
				tap(newCost => {
					const currentCosts = this.projectCosts.getValue();
					this.projectCosts.next([newCost, ...currentCosts]);
					const currentTotal = this.totalCostsCount.getValue();
					this.totalCostsCount.next(currentTotal + 1);
					this.getProjectCostTotals(projectId).subscribe();
				})
			)
	}

	public updateCost(costId: string, formData: { amount: number; description: string; category: string }): Observable<Cost> {
		return this.http.put<{ cost: Cost }>(`${Constants.BASE_API_URL}/project/costs/${costId}`, formData)
			.pipe(
				map(response => response.cost),
				tap(updatedCost => {
					const currentCosts = this.projectCosts.getValue();
					const index = currentCosts.findIndex(cost => cost.id === costId);

					if (index !== -1) {
						const updatedCosts = [...currentCosts];
						updatedCosts[index] = updatedCost;
						this.projectCosts.next(updatedCosts);
					}
					this.getProjectCostTotals(updatedCost.project).subscribe();
				})
			);
	}

	public deleteCost(costId: string): Observable<void> {
		return this.http.delete<void>(`${Constants.BASE_API_URL}/project/costs/${costId}`)
			.pipe(
				tap(() => {
					const currentCosts = this.projectCosts.getValue();
					const index = currentCosts.findIndex(cost => cost.id === costId);

					if (index !== -1) {
						const updatedCosts = [...currentCosts];
						updatedCosts.splice(index, 1);
						this.projectCosts.next(updatedCosts);
						const currentTotal = this.totalCostsCount.getValue();
						this.totalCostsCount.next(currentTotal - 1);
						this.getProjectCostTotals(updatedCosts[index].project).subscribe();
					}
				}));
	}

	public paginateProjectCosts(skip: number, limit: number, projectId: string): Observable<ItemsResponseData<Cost>> {
		return this.http.get<ItemsResponseData<Cost>>(
			`${Constants.BASE_API_URL}/project/costs/${projectId}/skip/${skip}/limit/${limit}`).pipe(tap(response => {
				this.projectCosts.next(response.items);
				if (response.total !== undefined) {
					this.totalCostsCount.next(response.total)
				}
			})
		);
	}

	public getProjectCostTotals(projectId: string): Observable<ProjectCostsTotals> {
		return this.http.get<{ totalAmount: number; breakdown: ProjectCostsTotals['breakdown'] }>(
			`${Constants.BASE_API_URL}/project/costs/${projectId}/total`
		).pipe(
			tap((summary) => this.totalProjectCosts.next(summary))
		);
	}

}
import { Validators } from '@angular/forms';

import { ValidatorHelper } from '../helpers';
import { CustomValidators } from '../helpers';
import { AddressDetail } from './address-detail';
import { ContactDetail } from './contact-detail';

// Interface

export interface SupplierProduct {
	name: string;
	weeklyHireRate: number;
	dailyHireRate: number;
	transportCost: number;
	thumbnailKey: string;
	thumbnailUrl: string;
	thumbnailFile?: File;
}

export interface Supplier {
	id: string;
	name: string;
	isActive: boolean;

	addressDetail: AddressDetail;
	contactDetail: ContactDetail;

	mainContact: {
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
	};

	products: SupplierProduct[];
}

// Responses

export interface SuppliersResponse {
	status: boolean;
	suppliers?: Supplier[];
	items?: Supplier[];
}

export interface SupplierResponse {
	status: boolean;
	supplier?: Supplier;
}

// Validators

export function GetSupplierValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Email',
			validators: [Validators.email],
		},
		{
			name: 'Name',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'AddressLine1',
			validators: [Validators.required],
		},
		{
			name: 'Country',
			validators: [Validators.required],
		},
		{
			name: 'State',
			validators: [Validators.required],
		},
		{
			name: 'SupplierType',
			validators: [Validators.required],
		},
		{
			name: 'Phone',
			validators: [],
		},
	]);
}

export function GetSupplierProductValidation(name, dailyHireRate, weeklyHireRate, transportCost) {
	return ValidatorHelper.formBuilder([
		{
			name: 'Name',
			validators: [Validators.required, CustomValidators.noWhiteSpaceValidator],
			value: name,
		},
		{
			name: 'DailyHireRate',
			validators: [Validators.required],
			value: dailyHireRate,
		},
		{
			name: 'WeeklyHireRate',
			validators: [Validators.required],
			value: weeklyHireRate,
		},
		{
			name: 'TransportCost',
			validators: [Validators.required],
			value: transportCost,
		},
	]);
}

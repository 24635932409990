import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ArrowLeft, LucideAngularModule } from 'lucide-angular';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '../button/button.component';
import { NavRoute } from '../../../app/interfaces/nav-route';

@Component({
	selector: 'paint-shared-sidebar',
	templateUrl: 'shared-sidebar.component.html',
	imports: [
		NgClass,
		RouterLink,
		RouterLinkActive,
		LucideAngularModule,
		TranslocoPipe,
		MatTooltipModule,
		ButtonComponent,
	],
	standalone: true,
}) export class SharedSidebarComponent {
	@Input() navRoutes:  NavRoute[] = [];
	public isMobileMenuOpen: boolean = false;
	public selectedEnvironment = environment.env;

	constructor(private router: Router, private cdr: ChangeDetectorRef) {
		console.log(this.navRoutes)
	}

	public toggleMobileMenu() {
		this.isMobileMenuOpen = !this.isMobileMenuOpen;
	}

	public closeMobileMenu() {
		this.isMobileMenuOpen = false;
	}

	public handleNavigation(route: NavRoute): void {
		if (route.subRoutes && !route.disabled) {
			route.isExpanded = !route.isExpanded;
		} else {
			this.router.navigate([route.route]);
		}
		this.toggleMobileMenu();
	}

	public toggleSubRoutes(route: NavRoute, event: Event): void {
		event.stopPropagation();
		if (!route.disabled) {
			route.isExpanded = !route.isExpanded;
		}
		this.cdr.detectChanges();
	}

	protected readonly ArrowLeft = ArrowLeft;
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { EntityEditRequest } from '../interfaces';
import { EntityModel } from '../models';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class EntityService {
	constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

	/**
	 * Updates an entity
	 * @param updateRequest
	 */
	public editEntity(updateRequest: EntityEditRequest): Observable<EntityModel> {
		return this.http.post<any>(`${Constants.BASE_API_URL}/entity/edit`, updateRequest).pipe(map(res => res.item));
	}

	/**
	 * Get an entity
	 * */
	public getEntity(id: string): Observable<EntityModel> {
		return this.http.get<any>(`${Constants.BASE_API_URL}/entity/${id}`).pipe(map(res => res.item))
	}

	/**
	 * Get the current user's entity
	 */
	public getCurrentEntity(): Observable<EntityModel> {
		const userData = this.localStorageService.getUserDetails();
		const entity = userData.entity;
		return of(new EntityModel(entity));
	}
}

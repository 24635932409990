export const environment = {
	env: 'production',
	intercomAppId: 'ssl1wxqp',
	production: true,
	googleMapsAPIKey: 'AIzaSyC4OOpkzFJ-6WFzRHcBqG-H7XYOjxs7iaI',
	stripePublishableAPIKey: 'pk_live_51J5Kh0EgCSMDz09z8UfvZ9OoREi7J0MV3GrYYFJMTwsYqe5D3QPYucn8QFmIu7Vx4yuJ39R3G75nlYBqx5eFPHBG005cyYzGX1',
	colors: {
		primary0: '#0EABF7',
		primary: '#078BCB',
		primary2: '#067ab2',
		primary3: '#05699a',
		accentWhite: '#eff6ff',
		accent1: '#bfdbfe',
		accent2: '#60a5fa',
		lightPrimary: '#339bff26',
	},
	stripePlans: [
		{ plan: 'Tradie', key: 'price_1PXi3dEgCSMDz09z6BS1B5oN' },
		{ plan: 'Go Getter', key: 'price_1PXicWEgCSMDz09z2D9m2hSE' },
		{ plan: 'Professional', key: 'price_1LTDTVEgCSMDz09z7H9jb6t3' },
		{ plan: 'Business', key: 'price_1PXkPDEgCSMDz09zpMFNHGJa' },
	],
	posthog: true,
	countries: ['australia'],
	us: false,
	baseUrl: 'https://app.paintprojex.com'
};

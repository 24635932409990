export const filesFolders = 'files-folders';

export enum libraryRoutes {
	boq = 'boq',
	brands = 'brands',
	coatingSystem = 'coating-system',
	coatingSystems = 'coating-systems',
	colours = 'colours',
	measureTools = 'measureTools',
	competitors = 'competitors',
	divisions = 'divisions',
	fileFolders = 'files-folders',
	inclusionsExclusions = 'inclusions-exclusions',
	invoice = 'invoice',
	labourRates = 'labour-rates',
	marketingItems = 'marketing-items',
	officeFolder = 'office-folder',
	productPurchasing = 'product-purchasing',
	products = 'products',
	qaFolder = 'qa-folder',
	quote = 'quote',
	quoteProposal = 'quote-proposal',
	safetyFolder = 'safety-folder',
	siteFolder = 'site-folder',
	suppliers = 'suppliers',
	tenderDocument = 'tender-document',
	variationQuote = 'variation-quote',
}

export const LibraryPageText = {
	library: 'library',
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map } from 'rxjs';
import { ItemResponseData } from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Constants } from '../helpers';
import { ProjectRequest } from '../pages/submit-project/common/submit-project.model';

@Injectable()
export class ProjectRequestGuard {
	constructor(
		private http: HttpClient,
		private router: Router
	) {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const entityId = route.paramMap.get('id');

		return this.http.get<ItemResponseData<ProjectRequest>>(`${Constants.BASE_API_URL}/public-entity/${entityId}`).pipe(
			map(res => {
				if (!res.status) {
					return this.router.parseUrl('/login');
				} else {
					return true;
				}
			}),
			catchError(err => this.router.navigateByUrl('/login'))
		);
	}
}
